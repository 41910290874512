import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import { storageApiRef,identityApiRef } from '@backstage/core-plugin-api';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
} from '@backstage/core-plugin-api';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';
import { CustomTechRadarAPI } from './radar';
import {
  VisitsStorageApi,
  visitsApiRef,
} from '@backstage/plugin-home';

export const apis: AnyApiFactory[] = [

  // Implementation that relies on a provided storageApi
  createApiFactory({
    api: visitsApiRef,
    deps: {
      storageApi: storageApiRef,
      identityApi: identityApiRef,
    },
    factory: ({ storageApi, identityApi }) =>
      VisitsStorageApi.create({ storageApi, identityApi }),
  }),


  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory(techRadarApiRef, new CustomTechRadarAPI()),
];
